import { createContext, PropsWithChildren, useContext, useState } from 'react';

type AuthChallengeContextProps = {
  isChallenged: boolean;
  setIsChallenged: (value: boolean) => void;
};

export const AuthChallengeContext = createContext<AuthChallengeContextProps>(null);

export const AuthChallengeProvider = ({ children }: PropsWithChildren) => {
  const [isChallenged, setIsChallenged] = useState(false);

  return <AuthChallengeContext.Provider value={{ isChallenged, setIsChallenged }}>
    { children }
  </AuthChallengeContext.Provider>
};

export function useAuthChallenge() {
  const context = useContext(AuthChallengeContext);

  if (!context) {
    throw new Error('useAuth hook was called outside of AuthChallengeProvider context');
  }

  return context;
}
